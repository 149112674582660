import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Agility = () => (
  <Layout>
    <SEO title="Agility" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">
          Defining organizational agility
        </h1>
        <p>
          Agility in the case of Gray Hawk Land Solutions is the ability of our
          organization to, adapt, decide quickly, and succeed in a rapidly
          changing, contentious, & currently turbulent energy environment.
          Agility is not the opposite of stability—it is quite the contrary.
          Agility requires stability from Gray Hawk Land Solutions to remain a
          consistent partner to our clients. Agility needs two things. One is
          professional capability, which allows us to move fast—make decisions
          with speed, acurateness & responsiveness. Agility also requires
          stability, at Gray Hawk Land Solutions we provide a stable
          foundation—a guiding vision—of things that will never change. It’s
          this resolute belief in our founding principles that becomes a
          lauching pad for our company, it provides the anchor point that
          doesn’t change while a whole bunch of other things are changing
          constantly.
        </p>
        <p>
          In small business, stability is typically embodied in the founders &
          principals of the firm.{" "}
        </p>
        <p>
          The opposite of frail is something that gets stronger when you exert
          force or stress on it. In today’s environment—with enormous changes
          coming from both inside and outside of the energy industry—that’s what
          we think Gray Hawk Land Solutions is. That’s what we call agile: when
          you thrive on change and get stronger and it becomes a source of real
          competitive advantage.
        </p>
      </div>
    </div>
  </Layout>
)

export default Agility
